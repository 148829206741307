import {
  createComputed,
  createRoot,
  createSignal,
  getOwner,
  Owner,
  runWithOwner,
} from "solid-js";
import { useLocation, useNavigate } from "@solidjs/router";

import Log from "@repo/utils/Log";
import UProxy from "@repo/utils/UProxy";
import UString from "@repo/utils/UString";

const [initial, setInitial] = createRoot(() => createSignal(true));

export function useRouter() {
  const location = useLocation();
  const navigate = useNavigate();

  const resolvePath = (path: string) => {
    const url = new URL(
      path,
      // Ensure base url always has single trailing slash. By default /path and
      // /path/ will behave differently, trailing slash has more desirable
      // behavior. `location.search` will be omitted when new URL is
      // constructed, no need to include.
      `${window.location.origin}${UString.beforeSuffix(window.location.pathname, "/")}/`,
    );

    return `${
      // Ensure resulting path doesn't have a "/", this can happen when
      // navigating to ".." or ".", url matching in application code is easier
      // with single standard
      UString.beforeSuffix(url.pathname, "/") || "/"
    }${url.search}`;
  };

  const router = UProxy.merge(
    UProxy.merge(location, { initial }),
    UProxy.deepShim(
      {
        push(path: string) {
          navigate(resolvePath(path));
        },
        replace(path: string) {
          navigate(resolvePath(path), { replace: true });
        },
        back: Object.assign(
          () => {
            navigate(-1);
          },
          {
            or(defaultRoute: ".." | (string & {})) {
              if (window.history.length > 1) router.back();
              else router.replace(defaultRoute);
            },
          },
        ),
      },
      (original, path) =>
        (...args) => {
          Log.withTrace`${Log.fn([Log.color("#8f8", "AppRouter"), ...path], args)}`;
          return original(...args);
        },
    ),
  );

  return router;
}

type AppRouter = AppRouter.Context & {
  register(owner?: Owner): void;
};
declare namespace AppRouter {
  type Context = ReturnType<typeof useRouter>;
}

let registered: AppRouter.Context;

const AppRouter: AppRouter = new Proxy(
  {
    register(owner = getOwner()!) {
      if (!owner)
        throw new Error("AppRouter must be registered in a Router context.");
      runWithOwner(owner, () => {
        registered = useRouter();
        createComputed((prev) => {
          if (prev) setInitial(false);
          return registered.pathname;
        });
      });
    },
  } as AppRouter,
  {
    get(target: any, p) {
      if (p in target) return target[p];
      if (!registered)
        throw new Error("Attempted to use Router before registration.");
      // Rename to Router to make errors clearer
      const AppRouter: any = registered;
      return AppRouter[p];
    },
  },
);

export default AppRouter;
